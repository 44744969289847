import * as React from "react"
import { SEO } from "../components/seo"
import Layout from '../components/layout';
// import { StaticImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';
// import { Link } from "gatsby";
import Form from '../components/form-volunteer'

const HowToHelp = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="h-72 bg-gradient-to-tl from-primary-dark to-primary-light relative after:content-[''] after:block after:bg-mask after:bg-['100%'] after:bg-bottom after:bg-no-repeat after:absolute after:inset-x-0 after:-bottom-1 after:w-full after:h-36 after:z-10">
        <div className="absolute w-full text-center inset-x-0 top-1/2 -translate-y-1/2 z-10 lg:p-20">
        <h1 className="animate__animated animate__fadeInDown animate__slower font-heading text-center text-3xl lg:text-5xl text-white font-bold mb-4">Take Action With Kim!</h1>
        </div>
      </section>

      <section className="container mt-8 mx-auto pt-14 px-4 lg:px-48 text-gray-700">
        <div className="text-base leading-loose space-y-6 mb-4">
          <p className="text-xl font-light text-center">Join us today and learn how you can help bring new leadership to CA-45.</p>

          <p>There are many ways to support this campaign, both in person and remotely. Whether you knock on doors, put up a yard sign, or send text messages, there is a place for everyone in this movement. </p>

          <p>Anyone can volunteer, regardless of political experience, age, or ability. </p>
        </div>
        <Form />
      </section>
    </Layout>
  )
}

export default HowToHelp

export const Head = ({location}) => <SEO title="Take Action With Kim!" pathname={location.pathname} />
